<template>
  <q-tr :props="data" :class="handleBG">
    <q-td class="q-td text-left" rowspan="2">
      <q-checkbox
          size="xl"
          :model-value="!!items.find(({id}) => id === data.row.id)"
          color="amber-6"
          @click="handleCheck(data.row)"
      />
    </q-td>

    <q-td
        key="number"
        :props="data"
        auto-width
        rowspan="2"
    >
      {{ data.rowIndex + 1 }}
    </q-td>

    <q-td
        key="name"
        :props="data"
        rowspan="2"
    >
      <q-card style="min-width: 270px;width: 100%;">

        <q-img
            :src="data.row._embedded?.productOffer?.image || appOptions.fallbackImage"
            style="max-height: 190px;"
            @error="onImageLoadFailure">
          <div class="absolute-bottom text-subtitle2">
            {{ data.row._embedded?.productOffer?.name + (data.row.itemsStatus ? '(' + (data.row?.itemsStatus) + ')' : '') }}
          </div>

          <q-chip class="absolute-top-right text-weight-bold" color="orange" v-if="getDataSource(data.row)">
            {{ getDataSource(data.row) }}
          </q-chip>
        </q-img>

        <q-card-section v-if="data.row.errors" align="center">
          <q-chip square icon="error_outline" color="red" text-color="white">{{ $t(`Attention needed`) }}</q-chip>

          <q-tooltip class="q-px-sm text-left">
            <ul class="q-ma-none q-pl-md" style="max-width: 350px">
              <li class="text-caption text-weight-light" v-for="error in data.row.errors">
                {{ error }}
              </li>
            </ul>
          </q-tooltip>
        </q-card-section>

        <q-separator/>

        <q-card-actions align="center" :class="handleBG">
          <q-btn
              :label="$t(data.row._embedded?.productOffer?.type || '')"
              color="light-blue-9"
              icon="visibility"
              v-if="data.row._embedded?.productOffer?.id"
              @click="open"
          />

          <q-btn
              :label="$t(`Change`)"
              color="warning"
              icon="warning"
              v-if="isItemOfferChangeAllowed(data.row)"
              @click="searchOffer"
          />

          <q-tooltip class="q-px-sm text-left">
            {{ data.row.state }}
          </q-tooltip>
        </q-card-actions>

        <q-separator/>

        <q-card-section align="center" :class="handleBG" class="q-pa-none q-py-sm">
          <span v-if="data.row._embedded?.productOffer">
            <strong>
              {{
                `Store: ` + getLastPathComponent(data.row._embedded?.productOffer?._embedded?.virtualShop?._links.self.href)
              }}
            </strong>
          </span>
          <span v-else>
            <strong>
              {{ 'Purchase order item' }}
            </strong>
          </span>
        </q-card-section>
      </q-card>
    </q-td>

    <q-td
        key="price"
        :props="data"
        style="max-width: 150px; min-width: 124px; border:none"
    >
      <div class="row q-mb-xs items-center full-width">
        <div class="col">
          <q-input
              standout="bg-teal text-white"
              class="q-mr-sm"
              type="number"
              :model-value="data.row.purchasingPrice"
              :disable="disabled || disabledPrice"
              :label="$t('Purchasing price')"
              dense
              @update:model-value="updateStorageItem('purchasingPrice', $event)"
          />
        </div>
      </div>
      <div class="row items-center full-width">
        <div class="col">
          <q-input
              standout="bg-teal text-white"
              class="q-mr-sm"
              type="number"
              :model-value="data.row.price"
              :disable="disabled || disabledPrice"
              :label="$t('Price')"
              dense
              @update:model-value="updateStorageItem('price', $event)"
          />
        </div>
      </div>
    </q-td>

    <q-td
        key="weight"
        :props="data"
        style="max-width: 370px; min-width: 300px; border:none"
    >
      <div class="row q-mb-xs items-center full-width">
        <div class="col-6">
          <q-input
              standout="bg-teal text-white"
              class="q-mr-sm"
              :model-value="(data.row._embedded.productOffer || {}).weight"
              :disable="disabled || disabledDimensions || !data.row._embedded.productOffer"
              :label="$t('Weight')"
              dense
              @update:model-value="updateOffer('weight', $event)"
          />
        </div>

        <div class="col-6">
          <q-input
              standout="bg-teal text-white"
              type="number"
              :model-value="dimensions.x"
              :disable="disabled || disabledDimensions || !data.row._embedded.productOffer"
              :label="$t('X')"
              dense
              @update:model-value="updateOffer('dimensions', $event, 'x')"
          />
        </div>
      </div>

      <div class="row items-center full-width">
        <div class="col-6">
          <q-input
              standout="bg-teal text-white"
              type="number"
              class="q-mr-sm"
              :disable="disabled || disabledDimensions || !data.row._embedded.productOffer"
              :model-value="dimensions.y"
              :label="$t('Y')"
              dense
              @update:model-value="updateOffer('dimensions', $event, 'y')"
          />
        </div>

        <div class="col-6">
          <q-input
              standout="bg-teal text-white"
              type="number"
              :model-value="dimensions.z"
              :disable="disabled || disabledDimensions || !data.row._embedded.productOffer"
              :label="$t('Z')"
              dense
              @update:model-value="updateOffer('dimensions', $event, 'z')"
          />
        </div>
      </div>
    </q-td>
    <q-td
        key="expected"
        :props="data"
        style="min-width: 124px; max-width: 150px; border:none"
    >
      <q-input
          standout="bg-teal text-white "
          :model-value="isChangedCount && this.acceptance.state === 'new'?changeOfCount:data.row.quantityExpected || data.row.quantityExpected === 0? data.row.quantityExpected : data.row.count"
          :disable="!isQuantityExpectedEnabled()"
          :label="$t('Quantity')"
          @update:model-value="updateStorageItem('count', $event)"
      />
    </q-td>

    <q-td
        key="receiving"
        :props="data"
        style="min-width: 124px; max-width: 150px; border:none"
    >
      <q-input
          standout="bg-teal text-white"
          type="number"
          :model-value="isChangedCount&& this.acceptance.state !== 'new' && !!this.acceptance.state ? changeOfCount:data.row.quantityReal "
          :disable="!isQuantityAllocatedEnabled()"
          :label="$t('Quantity')"
          @update:model-value="updateStorageItem('count', $event)"
      />
    </q-td>

    <q-td
        key="placed"
        :props="data"
        style="min-width: 124px; max-width: 150px; border:none"
    >
      <q-input
          standout="bg-teal text-white"
          type="number"
          :model-value="data.row.quantityPlace"
          :disable="true"
          :label="$t('Quantity')"
          @update:model-value="updateStorageItem('count', $event)"
      />
    </q-td>

    <q-td
        key="sku"
        :props="data"
        style="min-width: 200px;"
        rowspan="2"
    >
      <q-select
          :model-value="product.sku"
          standout="bg-teal text-white"
          class="q-mb-xs"
          :options="this.barcodeOptions"
          @filter="filterBarcodes"
          :label="$t('Barcode')"
          :emit-value="true"
          menu-cover
          use-input
          hide-dropdown-icon
          clearable
          fill-input
          hide-selected
          @clear="handleRemoveBarcode"
          new-value-mode="add"
          @new-value="handleNewBarcode"
          @keydown.space="(event) => event.preventDefault()"
      >
        <template v-slot:option="scope">
          <q-item :key="scope" v-close-popup clickable @click="handleSku(scope)" class="cursor-pointer">
            <q-item-section>
              {{ scope.label }}
            </q-item-section>
          </q-item>
        </template>
      </q-select>

      <q-input
          standout="bg-teal text-white"
          class="q-mb-xs"
          type="text"
          :model-value="data.row.trackingNumber"
          :disable="disabled"
          :label="$t('Tracking number')"
          @update:model-value="updateStorageItem('trackingNumber', $event)"
      />

      <form-builder
          v-if="isBatch"
          :schema="dateSchema"
      />

      <q-input
          v-if="isBatch"
          standout="bg-teal text-white"
          class="q-mb-xs"
          type="text"
          :model-value="data.row.batch"
          :disable="disabled"
          :label="$t('Batch')"
          @update:model-value="updateStorageItem('batch', $event)"
      />
    </q-td>

    <q-td
        v-if="isAmazon"
        key="amazon"
        :props="data"
        rowspan="2"
        style="min-width: 200px;"
    >
      <q-input
          standout="bg-teal text-white"
          type="number"
          class="q-mb-xs"
          :model-value="(data.row?._embedded?.productOffer?.eav || {})['integrations-amazon-offer-fba-quantity']"
          :disable="disabled || !data.row._embedded.productOffer"
          :label="$t('FBA')"
          @update:model-value="updateOffer('eav', $event, 'integrations-amazon-offer-fba-quantity')"
      />
      <q-input
          standout="bg-teal text-white"
          type="number"
          class="q-mb-xs"
          :model-value="(data.row?._embedded?.productOffer?.eav || {})['integrations-amazon-offer-fbm-quantity']"
          :disable="disabled || !data.row._embedded.productOffer"
          :label="$t('FBM')"
          @update:model-value="updateOffer('eav', $event , 'integrations-amazon-offer-fbm-quantity')"
      />

      <div @dblclick="handleAsinOpen">
        <q-input
            standout="bg-teal text-white"
            type="text"
            class="q-mb-xs"
            :model-value="((data.row?._embedded?.productOffer?.eav || {})['integrations-amazon-offer-asin'] || {})[marketplace]"
            :disable="isASINblocked"
            :label="$t('ASIN')"
            @update:model-value="updateOffer('eav', $event, 'integrations-amazon-offer-asin')"
        />
      </div>

      <q-select
          v-model="selectedOption"
          :options="optionsFbaArray"
          class="q-mb-xs"
          option-value="key"
          option-label="value"
          label="FBA SKU"
          filled
          :disable="!data?.row?._embedded?.productOffer"
          :emit-value="true"
          menu-cover
      >
        <template v-slot:no-option>
          <q-item clickable @click="addOptionDialog = true">
            <q-item-section>Add Option</q-item-section>
            <q-item-section side>
              <q-icon name="add" color="primary"/>
            </q-item-section>
          </q-item>
        </template>
        <template v-slot:option="scope">
          <q-item :key="scope" clickable @click="handleClick('fba', scope)" class="cursor-pointer">
            <q-item-section>{{ scope.label }}</q-item-section>
          </q-item>
        </template>
        <template v-slot:after-options>
          <q-item clickable @click="addOptionDialog = true">
            <q-item-section>Add Option</q-item-section>
            <q-item-section side>
              <q-icon name="add" color="primary"/>
            </q-item-section>
          </q-item>
        </template>
      </q-select>
      <q-dialog v-model="addOptionDialog" persistent>
        <q-card>
          <q-card-section>
            <q-input v-model="newOption.key" label="Key"/>
            <q-input v-model="newOption.label" label="Label"/>
          </q-card-section>
          <q-card-actions align="right">
            <q-btn label="Cancel" @click="addOptionDialog = false"/>
            <q-btn label="Add" color="primary" :disable="!newOption.key || !newOption.label" @click="addNewOption"/>
          </q-card-actions>
        </q-card>
      </q-dialog>

      <!--      <q-input-->
      <!--          standout="bg-teal text-white"-->
      <!--          type="text"-->
      <!--          class="q-mb-xs"-->
      <!--          :model-value="fbaSku"-->
      <!--          :disable="!sourceId && !fbaKey"-->
      <!--          :label="$t('FBA SKU')"-->
      <!--          @update:model-value="updateOfferSku('eav', $event , 'fba')"-->
      <!--      />-->


      <q-select
          v-model="selectedOptionFbm"
          :options="optionsFbmArray"
          option-value="key"
          option-label="value"
          :emit-value="true"
          filled
          :disable="!data?.row?._embedded?.productOffer"
          label="FBM SKU"
          menu-cover
      >
        <template v-slot:no-option>
          <q-item clickable @click="addOptionDialogFBM = true">
            <q-item-section>Add Option</q-item-section>
            <q-item-section side>
              <q-icon name="add" color="primary"/>
            </q-item-section>
          </q-item>
        </template>
        <template v-slot:option="scope">
          <q-item :key="scope" clickable @click="handleClick('fbm', scope)" class="cursor-pointer">
            <q-item-section>{{ scope.label }}</q-item-section>
          </q-item>
        </template>
        <template v-slot:after-options>
          <q-item clickable @click="addOptionDialogFBM = true">
            <q-item-section>Add Option</q-item-section>
            <q-item-section side>
              <q-icon name="add" color="primary"/>
            </q-item-section>
          </q-item>
        </template>
      </q-select>
      <q-dialog v-model="addOptionDialogFBM" persistent>
        <q-card>
          <q-card-section>
            <q-input v-model="newOptionFBM.key" label="Key"/>
            <q-input v-model="newOptionFBM.label" label="Label"/>
          </q-card-section>
          <q-card-actions align="right">
            <q-btn label="Cancel" @click="addOptionDialogFBM = false"/>
            <q-btn label="Add" color="primary" :disable="!newOptionFBM.key || !newOptionFBM.label"
                   @click="addNewOptionFBM"/>
          </q-card-actions>
        </q-card>
      </q-dialog>
      <!--      <q-input-->
      <!--          standout="bg-teal text-white"-->
      <!--          type="text"-->
      <!--          :model-value="fbmSku"-->
      <!--          :disable="!sourceId && !fbmKey"-->
      <!--          :label="$t('FBM SKU')"-->
      <!--          @update:model-value="updateOfferSku('eav', $event , 'fbm' )"-->
      <!--      />-->
    </q-td>

    <q-td
        key="actions"
        style="max-width: 120px"
        :props="data"
        rowspan="2"
    >
      <q-btn-dropdown
          class="q-ml-sm"
          size="lg"
          flat round dense
          no-icon
          dropdown-icon="more_vert"
      >
        <q-list>
          <q-item clickable v-close-popup :disable="!isBarcodePrintButtonEnabled()">
            <q-item-section @click="printItemBarcode(data.row)">
              <q-item-label>
                {{ 'Print current barcode' }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup>
            <q-item-section @click="handleClickWhere">
              <q-item-label>
                {{ 'Where?' }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup :disable="!isItemStatusButtonEnabled()">
            <q-item-section @click="printSystemBarcode(data.row)">
              <q-item-label>
                {{ 'Generate&Print a system barcode' }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup>
            <q-item-section @click="toggleSupplies">
              <q-item-label>
                {{ 'Add supplies to Acceptance Item' }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup :disable="!isItemStatusButtonEnabled()">
            <q-item-section @click="updateItemStatus(data.row.itemsStatus)">
              <q-item-label>
                {{ blocked + ' items' }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup :disable="!disabled">
            <q-item-section @click="showAllocationModal(data.row)">
              <q-item-label>
                {{ 'Allocate items' }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup :disable="disabled">
            <q-item-section @click="removeItem(data.row)">
              <q-item-label>
                {{ 'Delete' }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
      <div class="row items-right justify-left">

        <q-icon
            v-if="data.row.isUpdated"
            name="info"
            color="warning"
            class="q-pl-md"
            size="md"
        >
          <q-tooltip>
            {{ $t('This item is changed and it will be saved!') }}
          </q-tooltip>
        </q-icon>
      </div>
    </q-td>
  </q-tr>
  <q-tr :props="data" :class="handleBG">
    <q-td
        key="price"
        :props="data"
        colspan="5"
        style="max-height: 50%"
    >
      <q-input
          standout="bg-teal text-white"
          type="textarea"
          :model-value="(data.row.comment)"
          :disable="this.acceptance.state ==='closed'"
          :label="$t('Comment')"
          @update:model-value="updateStorageItem('comment', $event)"
      />

      <br>

      <div>
        <q-btn
            color="dark"
            class="q-mx-sm"
            size="xs"
            :label="$t('Edit link')"
            no-caps
            @click="handleEdit"
        />

        <a v-if="ifImage" :href="data.row.image"
           target="_blank">{{ data.row.image }}</a>
        <q-popup-edit v-if="this.editLink" v-model="data.row.image" auto-save v-slot="scope"
                      style="align-content: center">
          <q-input type="text" v-model="scope.value" dense autofocus
                   @update:model-value="updateStorageItem('image', $event)"/>
        </q-popup-edit>
      </div>
    </q-td>
  </q-tr>

  <place-items-modal ref="placeItemsModal" @submit="handleAllocate"/>

  <order-product-supplies-modal ref="suppliesModal" @on-submit="save"/>

  <acceptance-allocation-modal ref="acceptanceAllocationModal"/>

  <print-quantity-modal ref="printQuantity"/>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

// Components
import PlaceItemsModal from '@/apps/app/components/modals/PlaceItemsModal'
import { transliterate as tr } from 'transliteration'
import moment from 'moment/moment'
import Template from '@/apps/app/pages/notifications/Template.vue'
import PrintQuantityModal from '@/apps/app/components/modals/PrintQuantityModal.vue'
import OrderProductSuppliesModal from '@/apps/app/components/modals/OrderProductSuppliesModal.vue'
import AcceptanceAllocationModal from '@/apps/app/components/modals/AcceptanceAllocationModal.vue'

export default {
  name: 'AcceptanceItemsRow',
  emits: ['change', 'open', 'search-offer', 'offer-change', 'item-allocate', 'check'],
  components: {
    AcceptanceAllocationModal,
    OrderProductSuppliesModal,
    PrintQuantityModal,
    Template,
    PlaceItemsModal
  },
  props: {
    isConfirmed: {
      type: Boolean,
      default () {
        return false
      }
    },
    items: {
      type: Array,
      default () {
        return []
      }
    },
    isNew: {
      type: Boolean,
      default () {
        return false
      }
    },
    isChecking: {
      type: Boolean,
      default () {
        return false
      }
    },
    isBatch: {
      type: Boolean,
      default () {
        return true
      }
    },
    isPalletized: {
      type: Boolean,
      default () {
        return false
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    isAmazon: {
      type: Boolean,
      default () {
        return false
      }
    },
    disabledDimensions: {
      type: Boolean,
      default () {
        return false
      }
    },
    disabledPrice: {
      type: Boolean,
      default () {
        return false
      }
    },
    data: {
      type: Object,
      required: true,
      default () {
        return null
      }
    },
    warehouse: {
      type: Object,
      required: true,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      product: {
        sku: null
      },
      barcodeOptions: [],
      changeOfCount: 0,
      isChangedCount: false,
      marketplace: '',
      sourceId: '',
      editLink: false,
      msku: null,
      optionsFbaArray: [],
      selectedOption: '',
      isAsinBlock: false,
      newOption: { key: '', label: '' },
      addOptionDialog: false,
      optionsFbmArray: [],
      selectedOptionFbm: '',
      newOptionFBM: { key: '', label: '' },
      addOptionDialogFBM: false,
      mskuFBM: null,
      shop: null,
    }
  },
  watch: {
    data: {
      handler () {
        this.product = this.data.row
      },
      deep: true
    }
  },
  mounted () {
    this.product = this.data.row
    this.barcodeOptions = this.product?._embedded?.productOffer?.barcodes

    this.handleSKU(this.data.row)
    this.marketplace = this.data.row?._embedded?.productOffer?.eav && this.data.row?._embedded?.productOffer?.eav['integrations-amazon-offer-marketplace'] ? this.data.row._embedded.productOffer.eav['integrations-amazon-offer-marketplace'] : 'ATVPDKIKX0DER'
    this.getMsku(this.marketplace)
    this.getMskuFBM(this.marketplace)

    let shopData = this.data.row?._embedded?.productOffer?._embedded?.virtualShop
    if (!shopData) {
      shopData = this.data.row?._embedded?.productOffer?._embedded?.shop
    }
    if (shopData) {
      if (shopData.id) {
        this.shop = shopData.id
      } else {
        this.shop = shopData._links.self.href.split('/').pop()
      }
    }
  },
  computed: {
    ...mapGetters([
      'appOptions',
      'acceptance',
      'acceptanceItems',
    ]),
    handleBG () {
      if (this.data.row?.itemsStatus === 'blocked') {
        return 'bg-red-4'
      }

      if (this.data.row?.quantityReal > this.data.row?.quantityPlace) {
        return 'bg-yellow-4'
      }

      if (this.data.row?.state === 'confirmed') {
        return 'bg-green-4'
      }

      return ''
    },
    ifImage () {
      if (this.data.row?._embedded?.productOffer?.image) {
        return true
      }
      return false
    },
    isASINblocked () {
      if (!this.data.row?._embedded?.productOffer?.eav) {
        return true
      }

      if (this.selectedOption && (this.selectedOption.length > 0 || this.selectedOptionFbm.length > 0)) {
        return false
      }

      if (this.isAsinBlock) {
        return false
      } else {
        if (!this.data.row._embedded.productOffer.eav['integrations-amazon-offer-asin']) {
          return false
        }
        if (this.data.row._embedded.productOffer.eav['integrations-amazon-offer-asin'][this.marketplace] && this.data.row._embedded.productOffer.eav['integrations-amazon-offer-asin'][this.marketplace].length < 10) {
          return false
        }
        if (this.data.row._embedded.productOffer.eav['integrations-amazon-offer-asin'][this.marketplace] && this.data.row._embedded.productOffer.eav['integrations-amazon-offer-asin'][this.marketplace].length === 10) {
          return true
        }
      }
      return true
    },
    blocked () {
      if (this.data.row.itemsStatus !== 'blocked') {
        return 'Block'
      }
      return 'Unblock'
    },
    dimensions () {
      return (this.data.row._embedded.productOffer && this.data.row._embedded.productOffer.dimensions) || {}
    },
    dateSchema () {
      return {
        groups: [
          {
            fields: [
              {
                type: 'date',
                value: this.data.row.productionDate,
                field: 'productionDate',
                label: this.$t('Production Date'),
                max: '3000-01-01',
                wrapperStyleClasses: 'q-pb-xs',
                onError: () => {
                  this.addErrorNotification(`${this.$t('Maximum date is')} 3000-01-01`)
                },
                onChange: (value) => {
                  // eslint-disable-next-line vue/no-mutating-props
                  this.data.row.productionDate = value
                  // eslint-disable-next-line vue/no-mutating-props
                  this.data.row.isUpdated = true
                  this.$emit('change', this.data.row)
                }
              },
              {
                type: 'date',
                value: this.data.row.expires,
                field: 'expires',
                label: this.$t('Expiration Date'),
                max: '3000-01-01',
                wrapperStyleClasses: 'q-pb-xs',
                onError: () => {
                  this.addErrorNotification(`${this.$t('Maximum date is')} 3000-01-01`)
                },
                onChange: (value) => {
                  // eslint-disable-next-line vue/no-mutating-props
                  this.data.row.expires = value
                  // eslint-disable-next-line vue/no-mutating-props
                  this.data.row.isUpdated = true
                  this.$emit('change', this.data.row)
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    getLastPathComponent (url) {
      if (!url) return '' // Handle empty URL
      const path = new URL(url).pathname // Extract pathname from URL
      const pathComponents = path.split('/') // Split pathname into components
      return pathComponents[pathComponents.length - 1] // Get the last component
    },
    handleSKU (product) {
      let sourceId = ''

      if (product && product._embedded && product._embedded.productOffer && product._embedded.productOffer.eav && product._embedded.productOffer.eav['integrations-source-id']) {
        sourceId = product._embedded.productOffer.eav['integrations-source-id']
        this.sourceId = sourceId
      }
    },
    handleNewBarcode (value) {
      if (this.product?._embedded?.productOffer) {
        let barcodes = []
        if (this.product?._embedded?.productOffer?.barcodes) {
          barcodes = Object.values(this.product._embedded.productOffer.barcodes)
        }

        barcodes.push(value)

        this.product._embedded.productOffer.barcodes = barcodes
      }

      this.product.sku = value
      this.product.isUpdated = true

      this.$emit('change', {
        row: this.product
      })
    },
    handleSku (value) {
      this.product.sku = value.opt
      this.product.isUpdated = true

      this.$emit('change', {
        row: this.product
      })
    },
    handleRemoveBarcode () {
      this.product.sku = null
      this.product.isUpdated = true

      this.$emit('change', {
        row: this.product
      })
    },
    filterBarcodes (val, update) {
      update(() => {
        const needle = val

        if (this.product?._embedded?.productOffer?.barcodes) {
          this.barcodeOptions = Object.values(this.product?._embedded?.productOffer?.barcodes).filter(v => v.toLowerCase().indexOf(needle) > -1)
        }
      })
    },
    handleCheck () {
      this.$emit('check', this.data.row)
    },
    onImageLoadFailure (e) {
      e.target.src = this.appOptions.fallbackImage
    },
    open () {
      this.$emit('open', this.data.row)
    },
    searchOffer () {
      this.$emit('search-offer', this.data.row)
    },
    // updateOfferSku (key, value, types) {
    //   const type = types === 'fba' ? 'integrations-amazon-offer-sku-fba' : 'integrations-amazon-offer-sku-fbm'
    //   const checkKey = types === 'fba' ? this.fbaKey : this.fbmKey
    //   const objectKey = checkKey ? checkKey : this.sourceId
    //   const marketplace = this.marketplace ? this.marketplace : 'ATVPDKIKX0DER'
    //   const update = {
    //     [key]: {
    //       [type]: {
    //         [marketplace]: {
    //           [objectKey]: value
    //         }
    //       }
    //     }
    //   }
    //   this.$emit('offer-change', { id: this.data.row._embedded.productOffer.id, update })
    // },
    handleAsinOpen () {
      this.isAsinBlock = !this.isAsinBlock
    },
    updateOffer (key, value, subkey) {
      if (subkey === 'integrations-amazon-offer-asin') {
        const update = subkey
            ? {
              [key]: {
                ...this.data.row._embedded.productOffer[key],
                [subkey]: {
                  [this.marketplace]: value
                }
              }
            }
            : {
              [key]: value
            }
        this.$emit('offer-change', { id: this.data.row._embedded.productOffer.id, shop: this.shop, update })
      } else {
        const update = subkey
            ? {
              [key]: {
                ...this.data.row._embedded.productOffer[key],
                [subkey]: value
              }
            }
            : {
              [key]: value
            }

        this.$emit('offer-change', { id: this.data.row._embedded.productOffer.id, shop: this.shop, update })
      }
    },
    removeItem () {
      // eslint-disable-next-line vue/no-mutating-props
      this.data.row.count = 0

      // eslint-disable-next-line vue/no-mutating-props
      this.data.row.isUpdated = true

      this.$emit('change', this.data.row)
    },
    handleAllocate () {
      this.$emit('item-allocate', {})
    },
    printSystemBarcode (value) {
      value.isUpdated = true

      if (value._embedded?.productOffer?.id) {
        value.sku = 'S/I/PO' + value._embedded?.productOffer?.id + '*'
      } else {
        value.sku = 'S/I/POI' + value.id + '*'
      }

      this.printItemBarcode(value)

      return this.$service.acceptanceItem.save({
        sku: value.sku
      }, value.id)
          .then(acceptanceItem => {
            console.log(acceptanceItem)

            this.$refs.acceptanceItems.refresh()
          })
    },
    printItemBarcode (value) {
      const warehouse = tr(this.acceptance?._embedded?.warehouse.name)
      const formattedDate = moment(this.acceptance?.created.date).format('MM/DD/YYYY') // Update the format as per your requirement
      this.$refs.printQuantity.open(this.acceptance, warehouse, formattedDate, value)
    },
    updateItemStatus (value) {
      let result = value
      if (result === 'blocked') {
        result = 'normal'
      } else if (result === 'normal') {
        result = 'blocked'
      }
      this.updateStorageItem('itemsStatus', result)
    },
    updateStorageItem (key, value, subkey) {
      console.log(value)
      console.log(key)
      console.log(this.product)
      if (value.opt) {
        value = value.opt
      }

      if (key === 'count') {
        this.changeOfCount = value
        this.isChangedCount = true
      }

      if (key === 'quantityExpected' && value <= 0) {
        this.addErrorNotification('Minimum quantity is 1')
        return
      }
      if (key === 'quantityReal' && value <= 0) {
        this.addErrorNotification('Minimum quantity is 1')
        return
      }

      // We have different types of acceptances
      // We work with items which we group by offer, barcode, dimensions and etc.
      // This give possibility to have items without something unique
      // Also some methods can be async so we can't use index, it is not secure
      // We can't recognized which item to replace with new instance so we mutate them directly
      if (subkey) {
        // eslint-disable-next-line vue/no-mutating-props
        this.data.row[key][subkey] = value
      } else {
        // eslint-disable-next-line vue/no-mutating-props
        this.data.row[key] = value
      }
      // eslint-disable-next-line vue/no-mutating-props
      this.data.row.isUpdated = true
      this.$emit('change', this.data.row)
    },
    isItemOfferChangeAllowed (row) {
      return !row._embedded?.productOffer?.id || row._embedded?.acceptance?.state === 'new' || this.acceptance.itemsStatus === 'blocked' || row.errors || row.state === 'error'
    },
    isItemStatusButtonEnabled () {
      return (this.data.row.itemsStatus === 'blocked' && this.acceptance.state !== 'new') || this.acceptance.state === 'new'
    },
    isBarcodePrintButtonEnabled () {
      // (this.data.row.id && this.acceptance.state !== 'new') || this.acceptance.state === 'new'
      return this.data.row.sku
    },
    isQuantityExpectedEnabled () {
      return this.acceptance.state === 'new' || !this.acceptance.state
    },
    isQuantityAllocatedEnabled () {
      return this.acceptance.state === 'confirmed' || this.acceptance.state === 'checking'
    },
    showAllocationModal (row) {
      this.$refs.placeItemsModal.show(this.acceptance, [row], this.warehouse, row._embedded?.acceptance?.type === 'palletized' ? 'pallet' : 'static')
    },
    toggleSupplies () {
      this.$refs.suppliesModal.open()
    },
    save (service) {
      service['acceptanceItem'] = this.data.row.id
      return this.$service.acceptanceService.save({ ...service, acceptance: this.acceptance.id })
    },
    getDataSource (row) {
      if (row._embedded?.productOffer?.eav) {
        if (Object.keys(row._embedded.productOffer.eav).includes('integrations-source-type')) {
          return row._embedded?.productOffer?.eav['integrations-source-type']
        }
      }

      return false
    },
    handleClick (field, key) {
      if (field === 'fba') {
        const test = key.label.split(':')
        this.$service.offer.save({ eav: { 'integrations-amazon-offer-sku-fba': { [this.marketplace]: { [test[0]]: test[1] } } } }, this.data.row._embedded.productOffer.id, this.shop, {})
            .then(item => {
              this.getMsku(this.marketplace)
              this.$router.go()
            })
      } else {
        const test = key.label.split(':')
        this.$service.offer.save({ eav: { 'integrations-amazon-offer-sku-fbm': { [this.marketplace]: { [test[0]]: test[1] } } } }, this.data.row._embedded.productOffer.id, this.shop, {})
            .then(item => {
              this.getMskuFBM(this.marketplace)
              this.$router.go()
            })
      }
    },
    getMskuFBM (marketplace) {
      if (this.data?.row?._embedded?.productOffer?.eav && this.data?.row?._embedded?.productOffer?.eav['integrations-amazon-offer-sku-fbm-list'] && marketplace) {
        this.mskuFBM = this.data.row._embedded.productOffer.eav['integrations-amazon-offer-sku-fbm-list'][marketplace]
        if (Array.isArray(this.mskuFBM) && this.mskuFBM) {

          this.optionsFbmArray = this.mskuFBM.map(option => {
            const key = Object.keys(option)[0]
            const value = option[key]
            return `${key}: ${value}`
          })
        }
      }

      if (this.data?.row?._embedded?.productOffer?.eav && this.data?.row?._embedded?.productOffer?.eav['integrations-amazon-offer-sku-fbm'] && marketplace) {
        const choosenMsku = this.data.row._embedded.productOffer.eav['integrations-amazon-offer-sku-fbm'][marketplace]
        if (choosenMsku) {
          this.selectedOptionFbm = [choosenMsku].map(option => {
            const key = Object.keys(option)[0]
            const value = option[key]
            return `${key}: ${value}`
          })
        }
      }
    },
    getMsku (marketplace) {
      if (this.data?.row?._embedded?.productOffer?.eav && this.data?.row?._embedded?.productOffer?.eav['integrations-amazon-offer-sku-fba-list'] && marketplace) {
        this.msku = this.data.row._embedded.productOffer.eav['integrations-amazon-offer-sku-fba-list'][marketplace]
        if (Array.isArray(this.msku) && this.msku) {

          this.optionsFbaArray = this.msku.map(option => {
            const key = Object.keys(option)[0]
            const value = option[key]
            return `${key}: ${value}`
          })
        }
      }

      if (this.data?.row?._embedded?.productOffer?.eav && this.data?.row?._embedded?.productOffer?.eav['integrations-amazon-offer-sku-fba'] && marketplace) {
        const choosenMsku = this.data.row._embedded.productOffer.eav['integrations-amazon-offer-sku-fba'][marketplace]
        if (choosenMsku) {
          this.selectedOption = [choosenMsku].map(option => {
            const key = Object.keys(option)[0]
            const value = option[key]
            return `${key}: ${value}`
          })
        }
      }
    },
    addNewOption () {

      this.optionsFbaArray.push(`${this.newOption.key}:${this.newOption.label}`)
      let updatedList = []
      if (Array.isArray(this.msku) && this.msku) {
        updatedList = [...this.msku, { [this.newOption.key]: this.newOption.label }]
        this.$service.offer.save({ eav: { 'integrations-amazon-offer-sku-fba-list': { [this.marketplace]: updatedList } } }, this.data.row._embedded.productOffer.id, this.shop, {})
      } else {
        this.$service.offer.save({ eav: { 'integrations-amazon-offer-sku-fba-list': { [this.marketplace]: [{ [this.newOption.key]: this.newOption.label }] } } }, this.data.row._embedded.productOffer.id, this.shop, {})
      }
      this.newOption.key = ''
      this.newOption.label = ''
      this.addOptionDialog = false
    },
    addNewOptionFBM () {

      this.optionsFbmArray.push(`${this.newOptionFBM.key}:${this.newOptionFBM.label}`)
      let updatedList = []
      if (Array.isArray(this.mskuFBM) && this.mskuFBM) {
        updatedList = [...this.mskuFBM, { [this.newOptionFBM.key]: this.newOptionFBM.label }]
        this.$service.offer.save({ eav: { 'integrations-amazon-offer-sku-fbm-list': { [this.marketplace]: updatedList } } }, this.data.row._embedded.productOffer.id, this.shop, {})
      } else {
        this.$service.offer.save({ eav: { 'integrations-amazon-offer-sku-fbm-list': { [this.marketplace]: [{ [this.newOptionFBM.key]: this.newOptionFBM.label }] } } }, this.data.row._embedded.productOffer.id, this.shop, {})
      }
      this.newOptionFBM.key = ''
      this.newOptionFBM.label = ''
      this.addOptionDialogFBM = false
    },
    handleClickWhere () {
      this.$refs.acceptanceAllocationModal.open(this.acceptance, this.data.row)
    },
    handleEdit () {
      this.editLink = !this.editLink
    },
    handleEditImage (field, value) {
      this.$service.offer.save({ image: value }, this.data.row._embedded.productOffer.id, this.shop, {})
    }
  }
}
</script>
